import React from 'react';
import { LoaderContainer, Spinner } from './loading.styles';

const Loading = () => (
    <LoaderContainer>
        <Spinner />
    </LoaderContainer>
);

export default Loading;
