import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyD-rErSFFCF4WPUxj_Ial7HrXJP_NfP3mo',
    authDomain: 'lbml-app.firebaseapp.com',
    projectId: 'lbml-app',
    storageBucket: 'lbml-app.appspot.com',
    messagingSenderId: '811216054731',
    appId: '1:811216054731:web:e2d7f7c47a4b78fff6ae4e',
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

export default app;
