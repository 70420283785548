import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FaTrophy } from 'react-icons/fa';

import { Bio, ProfileContainer, SectionTitle } from './player.styles';

import {
    Loading,
    ProfileHeader,
    ProfileHeroes,
    ProfileInfo,
    ProfilePositions,
    ProfileStats,
} from '../../../components/';

import { profileService } from '../../../services';

const PlayerPage = () => {
    const [profile, setProfile] = useState(null);

    const { playerId } = useParams();

    useEffect(() => {
        const fetchUserProfile = async () => {
            const profileData = await profileService.getProfile(playerId);

            setProfile(profileData);
        };

        fetchUserProfile();
    }, [playerId]);

    if (!profile) return <Loading />;

    return (
        <ProfileContainer>
            <ProfileHeader profile={profile} />

            <div>
                <ProfileStats stats={profile.stats} />

                <SectionTitle>Bio</SectionTitle>
                <Bio>{profile.bio}</Bio>

                <SectionTitle>Player Info</SectionTitle>
                <ProfileInfo profile={profile} />

                <SectionTitle>Preferred Positions</SectionTitle>
                <ProfilePositions profile={profile} />

                <SectionTitle>Favorite Heroes</SectionTitle>
                <ProfileHeroes profile={profile} />

                <SectionTitle>Achievements</SectionTitle>
                <ul>
                    {profile.achievements.map((achievement, index) => (
                        <li key={index}>
                            <FaTrophy style={{ marginRight: '5px' }} />
                            {achievement}
                        </li>
                    ))}
                </ul>
            </div>
        </ProfileContainer>
    );
};

export default PlayerPage;
