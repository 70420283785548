import React, { createContext, useContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { lightTheme, darkTheme } from '../themes';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

const ThemeProvider = ({ children }) => {
    const theme = 'dark';

    return (
        <ThemeContext.Provider value={{ theme }}>
            <StyledThemeProvider
                theme={theme === 'light' ? lightTheme : darkTheme}
            >
                {children}
            </StyledThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
