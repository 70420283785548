import React, { useState, useEffect } from 'react';

import {
    FaTrophy,
    FaUsers,
    FaNewspaper,
    FaCalendarAlt,
    FaSearch,
    FaBell,
} from 'react-icons/fa';
import {
    ActionButton,
    ActionButtons,
    Card,
    CardIcon,
    CardTitle,
    Grid,
    Header,
    HomeContainer,
    List,
    ListItem,
    NewsDate,
    NewsItem,
    StatItem,
    StatLabel,
    StatValue,
    TeamStats,
    Title,
} from './dashboard.styles';

const HomePage = () => {
    const [upcomingMatches, setUpcomingMatches] = useState([]);
    const [news, setNews] = useState([]);
    const [teamStats, setTeamStats] = useState({});

    useEffect(() => {
        // Fetch data from API
        setUpcomingMatches([
            { id: 1, opponent: 'Team A', date: '2024-08-18 15:00' },
            { id: 2, opponent: 'Team B', date: '2024-08-20 18:30' },
        ]);
        setNews([
            { id: 1, date: '2024-08-16', title: 'New Season Announced!' },
            {
                id: 2,
                date: '2024-08-15',
                title: 'Balance Changes in Upcoming Patch',
            },
        ]);
        setTeamStats({
            rank: 5,
            wins: 15,
            losses: 5,
        });
    }, []);

    return (
        <HomeContainer>
            <Header>
                <Title>Welcome, Player!</Title>
                <ActionButtons>
                    <ActionButton>
                        <FaSearch /> Find Team
                    </ActionButton>
                    <ActionButton>
                        <FaBell />
                    </ActionButton>
                </ActionButtons>
            </Header>
            <Grid>
                <Card>
                    <CardTitle>
                        <CardIcon>
                            <FaCalendarAlt />
                        </CardIcon>{' '}
                        Upcoming Matches
                    </CardTitle>
                    <List>
                        {upcomingMatches.map((match) => (
                            <ListItem key={match.id}>
                                {new Date(match.date).toLocaleString()} - vs{' '}
                                {match.opponent}
                            </ListItem>
                        ))}
                    </List>
                </Card>
                <Card>
                    <CardTitle>
                        <CardIcon>
                            <FaNewspaper />
                        </CardIcon>{' '}
                        Latest News
                    </CardTitle>
                    <List>
                        {news.map((item) => (
                            <NewsItem key={item.id}>
                                <NewsDate>{item.date}</NewsDate>
                                {item.title}
                            </NewsItem>
                        ))}
                    </List>
                </Card>
                <Card>
                    <CardTitle>
                        <CardIcon>
                            <FaUsers />
                        </CardIcon>{' '}
                        My Team
                    </CardTitle>
                    <TeamStats>
                        <StatItem>
                            <StatValue>#{teamStats.rank}</StatValue>
                            <StatLabel>Rank</StatLabel>
                        </StatItem>
                        <StatItem>
                            <StatValue>{teamStats.wins}</StatValue>
                            <StatLabel>Wins</StatLabel>
                        </StatItem>
                        <StatItem>
                            <StatValue>{teamStats.losses}</StatValue>
                            <StatLabel>Losses</StatLabel>
                        </StatItem>
                    </TeamStats>
                </Card>
                <Card>
                    <CardTitle>
                        <CardIcon>
                            <FaTrophy />
                        </CardIcon>{' '}
                        Active Competitions
                    </CardTitle>
                    <List>
                        <ListItem>Summer League 2024 - Ongoing</ListItem>
                        <ListItem>Ranked Season 25 - Ends in 14 days</ListItem>
                    </List>
                </Card>
            </Grid>
        </HomeContainer>
    );
};

export default HomePage;
