import React from 'react';
import {
    FaHome,
    FaUser,
    FaUsers,
    FaSearch,
    FaTrophy,
    FaBell,
    FaCog,
    FaQuestionCircle,
} from 'react-icons/fa';

import { CloseButton, Menu, MenuItem, Nav } from './side-menu.styles';

import { IoMdClose } from 'react-icons/io';

const AppSideMenu = ({ isOpen, closeMenu, isMini }) => {
    const menuItems = [
        { to: '/home', icon: FaHome, label: 'Início' },
        { to: '/my-profile', icon: FaUser, label: 'Meu Perfil' },
        { to: '/my-team', icon: FaUsers, label: 'Minha Equipe' },
        { to: '/search', icon: FaSearch, label: 'Buscar' },
        { to: '/competitions', icon: FaTrophy, label: 'Competições' },
        { to: '/notifications', icon: FaBell, label: 'Notificações' },
        { to: '/settings', icon: FaCog, label: 'Configurações' },
        { to: '/help', icon: FaQuestionCircle, label: 'Ajuda' },
    ];

    return (
        <Nav $isOpen={isOpen}>
            <CloseButton onClick={closeMenu}>
                <IoMdClose />
            </CloseButton>
            <Menu>
                {menuItems.map((item) => (
                    <MenuItem
                        key={item.label}
                        to={item.to}
                        $active={window.location.hash === item.to}
                    >
                        <item.icon />
                        <span>{item.label}</span>
                    </MenuItem>
                ))}
            </Menu>
        </Nav>
    );
};

export default AppSideMenu;
