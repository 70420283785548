import React from 'react';
import styled from 'styled-components';

const HomePage = () => {
    return (
        <>
            <HeroSection>
                <WelcomeMessage>Bem-vindo à</WelcomeMessage>
                <HeroTitle>Liga Brasileira de Mobile Legends</HeroTitle>
                <HeroSubtitle>
                    Onde Sonhos Amadores se Tornam Realidade Profissional
                </HeroSubtitle>
                <CTAButton>Comece Sua Jornada</CTAButton>
            </HeroSection>
            <AnnouncementSection>
                <AnnouncementTitle>
                    Sua Porta de Entrada para o Cenário Profissional
                </AnnouncementTitle>
                <AnnouncementText>
                    A LNML é o lugar perfeito para jogadores amadores que sonham
                    em se tornar profissionais. Aqui você pode formar equipes,
                    conhecer outros jogadores e dar o primeiro passo rumo ao
                    estrelato!
                </AnnouncementText>
            </AnnouncementSection>
            <FeatureSection>
                <FeatureItem>
                    <FeatureIcon>🤝</FeatureIcon>
                    <FeatureText>Forme Sua Equipe</FeatureText>
                </FeatureItem>
                <FeatureItem>
                    <FeatureIcon>🌟</FeatureIcon>
                    <FeatureText>Desenvolva Seu Talento</FeatureText>
                </FeatureItem>
                <FeatureItem>
                    <FeatureIcon>🚀</FeatureIcon>
                    <FeatureText>Alcance o Profissionalismo</FeatureText>
                </FeatureItem>
            </FeatureSection>
            <CommunitySection>
                <CommunityTitle>Junte-se à Nossa Comunidade</CommunityTitle>
                <CommunityText>
                    Conecte-se com outros jogadores, forme alianças e cresça
                    junto com a comunidade LNML. Seu próximo companheiro de
                    equipe pode estar a um clique de distância!
                </CommunityText>
                <CommunityButton>Explorar Comunidade</CommunityButton>
            </CommunitySection>
        </>
    );
};

const HeroSection = styled.section`
    text-align: center;
    padding: 5rem 1rem;
`;

const WelcomeMessage = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #ffd700;
`;

const HeroTitle = styled.h1`
    font-size: 3rem;
    margin-bottom: 1rem;
`;

const HeroSubtitle = styled.h3`
    font-size: 1.25rem;
    margin-bottom: 2rem;
`;

const CTAButton = styled.button`
    background-color: #ffd700;
    color: #1a1a2e;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
        background-color: #ffec8b;
    }
`;

const AnnouncementSection = styled.section`
    background-color: rgba(255, 215, 0, 0.1);
    padding: 2rem;
    text-align: center;
    margin-bottom: 2rem;
`;

const AnnouncementTitle = styled.h2`
    font-size: 2rem;
    color: #ffd700;
    margin-bottom: 1rem;
`;

const AnnouncementText = styled.p`
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto;
`;

const FeatureSection = styled.section`
    display: flex;
    justify-content: space-around;
    padding: 2rem 1rem;
`;

const FeatureItem = styled.div`
    text-align: center;
`;

const FeatureIcon = styled.div`
    font-size: 3rem;
    margin-bottom: 0.5rem;
`;

const FeatureText = styled.p`
    font-size: 1rem;
`;

const CommunitySection = styled.section`
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3rem 2rem;
    text-align: center;
`;

const CommunityTitle = styled.h2`
    font-size: 2rem;
    color: #ffd700;
    margin-bottom: 1rem;
`;

const CommunityText = styled.p`
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto 2rem;
`;

const CommunityButton = styled(CTAButton)`
    background-color: #4caf50;
    &:hover {
        background-color: #45a049;
    }
`;

export default HomePage;
