import React, { useState, useEffect } from 'react';

import {
    FaEdit,
    FaTrophy,
    FaChartLine,
    FaUserCircle,
    FaStar,
    FaGamepad,
    FaUserPlus,
    FaComments,
} from 'react-icons/fa';

import {
    ActionButton,
    ManageButton,
    MemberAvatar,
    MemberCard,
    MemberGrid,
    MemberName,
    MemberRole,
    SectionTitle,
    StatCard,
    StatIcon,
    StatLabel,
    StatValue,
    TeamContainer,
    TeamHeader,
    TeamName,
    TeamStatsGrid,
} from './my-team.styles';

const MyTeamPage = () => {
    const [team, setTeam] = useState(null);

    useEffect(() => {
        // Fetch team data from API
        setTeam({
            name: 'Pro MLers',
            stats: {
                rank: 'Mythical Glory',
                matches: 200,
                winRate: '65%',
                trophies: 10,
            },
            members: [
                {
                    id: 1,
                    name: 'John Doe',
                    role: 'Captain',
                    position: 'Tank',
                    avatarUrl: '',
                },
                {
                    id: 2,
                    name: 'Jane Smith',
                    role: 'Member',
                    position: 'Marksman',
                    avatarUrl: '',
                },
                {
                    id: 3,
                    name: 'Mike Johnson',
                    role: 'Member',
                    position: 'Mage',
                    avatarUrl: '',
                },
                {
                    id: 4,
                    name: 'Sarah Brown',
                    role: 'Member',
                    position: 'Support',
                    avatarUrl: '',
                },
                {
                    id: 5,
                    name: 'Tom Wilson',
                    role: 'Member',
                    position: 'Fighter',
                    avatarUrl: '',
                },
            ],
            recentAchievements: [
                'MPL Amateur Champions Season 5',
                'City Tournament Winners 2023',
                'Ranked 1st in Regional Qualifiers',
            ],
            upcomingMatches: [
                { opponent: 'Team Awesome', date: '2023-06-15', time: '18:00' },
                { opponent: 'ML Legends', date: '2023-06-20', time: '20:00' },
            ],
        });
    }, []);

    if (!team) return <div>Loading...</div>;

    return (
        <TeamContainer>
            <TeamHeader>
                <TeamName>{team.name}</TeamName>
                <ManageButton>
                    <FaEdit style={{ marginRight: '5px' }} /> Manage Team
                </ManageButton>
            </TeamHeader>

            <TeamStatsGrid>
                <StatCard>
                    <StatIcon>
                        <FaTrophy />
                    </StatIcon>
                    <StatValue>{team.stats.rank}</StatValue>
                    <StatLabel>Team Rank</StatLabel>
                </StatCard>
                <StatCard>
                    <StatIcon>
                        <FaGamepad />
                    </StatIcon>
                    <StatValue>{team.stats.matches}</StatValue>
                    <StatLabel>Matches Played</StatLabel>
                </StatCard>
                <StatCard>
                    <StatIcon>
                        <FaChartLine />
                    </StatIcon>
                    <StatValue>{team.stats.winRate}</StatValue>
                    <StatLabel>Win Rate</StatLabel>
                </StatCard>
                <StatCard>
                    <StatIcon>
                        <FaStar />
                    </StatIcon>
                    <StatValue>{team.stats.trophies}</StatValue>
                    <StatLabel>Trophies</StatLabel>
                </StatCard>
            </TeamStatsGrid>

            <SectionTitle>Team Members</SectionTitle>
            <MemberGrid>
                {team.members.map((member) => (
                    <MemberCard key={member.id}>
                        <MemberAvatar>
                            {member.avatarUrl ? (
                                <img
                                    src={member.avatarUrl}
                                    alt={member.name}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            ) : (
                                <FaUserCircle size={50} color="#999" />
                            )}
                        </MemberAvatar>
                        <MemberName>{member.name}</MemberName>
                        <MemberRole>{member.role}</MemberRole>
                        <MemberRole>{member.position}</MemberRole>
                        <ActionButton>
                            <FaComments style={{ marginRight: '5px' }} />{' '}
                            Message
                        </ActionButton>
                    </MemberCard>
                ))}
                <MemberCard>
                    <MemberAvatar>
                        <FaUserPlus size={50} color="#999" />
                    </MemberAvatar>
                    <MemberName>Add Member</MemberName>
                    <ActionButton>
                        <FaUserPlus style={{ marginRight: '5px' }} /> Invite
                    </ActionButton>
                </MemberCard>
            </MemberGrid>

            <SectionTitle>Recent Achievements</SectionTitle>
            <ul>
                {team.recentAchievements.map((achievement, index) => (
                    <li key={index}>{achievement}</li>
                ))}
            </ul>

            <SectionTitle>Upcoming Matches</SectionTitle>
            {team.upcomingMatches.map((match, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                    <strong>{match.opponent}</strong> - {match.date} at{' '}
                    {match.time}
                </div>
            ))}
        </TeamContainer>
    );
};

export default MyTeamPage;
