// src/routes/appRoutes.js

import { LandingLayout } from '../layouts';

import { AboutPage, CalendarPage, HomePage } from '../pages/landing';

const appRoutes = {
    element: <LandingLayout />,
    children: [
        {
            element: <HomePage />,
            path: '/',
        },
        {
            element: <AboutPage />,
            path: '/sobre',
        },
        {
            element: <CalendarPage />,
            path: '/calendario',
        },
    ],
};

export default appRoutes;
