import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SearchContainer = styled.div`
    padding: 20px;
    font-family: Arial, sans-serif;
`;

export const SearchHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const SearchInput = styled.input`
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #4caf50;
    border-radius: 5px;
    color: black;
`;

export const IconButton = styled.button`
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #45a049;
    }
`;

export const TabContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const Tab = styled.button`
    background-color: ${(props) => (props.$active ? '#4CAF50' : '#f0f0f0')};
    color: ${(props) => (props.$active ? 'white' : 'black')};
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    flex-grow: 1;

    &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

export const ResultsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
`;

export const ResultCard = styled(Link)`
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    text-decoration: none;

    &:hover {
        transform: translateY(-5px);
        text-decoration: none;
    }
`;

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const Avatar = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`;

export const Name = styled.h3`
    margin: 0;
    font-size: 18px;
    color: black;
`;

export const StatList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

export const StatItem = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
`;

export const StatIcon = styled.span`
    margin-right: 5px;
    color: #4caf50;
`;

export const ActionButton = styled.button`
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;

    &:hover {
        background-color: #45a049;
    }
`;

export const FilterModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

export const FilterGroup = styled.div`
    margin-bottom: 15px;
`;

export const FilterLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
`;

export const FilterSelect = styled.select`
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
`;

export const FilterActions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

export const FilterButton = styled.button`
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
        background-color: #45a049;
    }
`;
