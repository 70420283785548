// src/components/AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    initialize as initializeAction,
    loginStart,
    loginFailure,
    loginSuccess,
} from '../store/slices';

import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
} from 'firebase/auth';

import { auth, profileService } from '../services';

import { Loading } from '../components';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const state = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const initialize = async () => {
        try {
            const unsubscribe = onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const profile = await profileService.getProfile(user.uid);

                    dispatch(initializeAction(profile));
                } else dispatch(initializeAction(null));
            });

            // Cleanup subscription on unmount
            return () => unsubscribe();
        } catch (error) {
            console.error('Error initializing authentication:', error);
            throw new Error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loginFn = async ({ email, password }) => {
        dispatch(loginStart());

        try {
            const userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password,
            );

            const { user } = userCredential;

            const profile = await profileService.getProfile(user.uid);

            dispatch(loginSuccess(profile));

            return userCredential;
        } catch (error) {
            dispatch(loginFailure(error.message));
            throw new Error(error.message);
        }
    };

    const registerFn = async ({ email, password, username }) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password,
            );

            const { user } = userCredential;

            await profileService.createProfile(user.uid, {
                uid: user.uid,
                username,
                email,
                emailVerified: user.emailVerified,
            });

            await logoutFn();

            return userCredential;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const logoutFn = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error('Logout Error:', error.message);
        }
    };

    if (isLoading) return <Loading />;

    const value = {
        state,
        user: state.user,
        registerFn,
        loginFn,
        logoutFn,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;
