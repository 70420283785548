import styled from 'styled-components';

export const TeamContainer = styled.div`
    padding: 20px;
    font-family: Arial, sans-serif;
`;

export const TeamHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const TeamName = styled.h1`
    margin: 0;
    font-size: 28px;
`;

export const ManageButton = styled.button`
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;

    &:hover {
        background-color: #45a049;
    }
`;

export const TeamStatsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    margin-bottom: 30px;
`;

export const StatCard = styled.div`
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
`;

export const StatIcon = styled.div`
    font-size: 24px;
    color: #007bff;
    margin-bottom: 5px;
`;

export const StatValue = styled.div`
    font-size: 18px;
    color: #000;
    font-weight: bold;
`;

export const StatLabel = styled.div`
    font-size: 14px;
    color: #666;
`;

export const SectionTitle = styled.h2`
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 15px;
    border-bottom: 2px solid #4caf50;
    padding-bottom: 10px;
`;

export const MemberGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
`;

export const MemberCard = styled.div`
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MemberAvatar = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 10px;
`;

export const MemberName = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
`;

export const MemberRole = styled.div`
    font-size: 14px;
    color: #666;
`;

export const ActionButton = styled.button`
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;

    &:hover {
        background-color: #45a049;
    }
`;
