import styled from 'styled-components';

export const InfoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
`;

export const InfoItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
`;

export const InfoIcon = styled.span`
    margin-right: 8px;
    color: #4caf50;
`;
