import styled from 'styled-components';

export const StatGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
`;

export const StatCard = styled.div`
    background-color: #f9f9f9; /* White background for contrast */
    border: 1px solid #e0e0e0; /* Light border for definition */
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
        background-color: #f9f9f9; /* Light hover effect */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
    }
`;

export const StatIcon = styled.div`
    font-size: 24px; /* Larger icon for better visibility */
    color: #007bff; /* Bright, attention-grabbing color */
    margin-bottom: 8px;
`;

export const StatValue = styled.div`
    font-size: 16px; /* Increased font size for value */
    font-weight: 600; /* Bolder font weight for emphasis */
    color: #333; /* Darker color for better readability */
`;

export const StatLabel = styled.div`
    font-size: 12px; /* Slightly larger label for better readability */
    color: #888; /* Softer color for less emphasis */
    margin-top: 4px;
`;
