import styled from 'styled-components';

export const HomeContainer = styled.div`
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const Title = styled.h1`
    font-size: 24px;
    margin: 0;
`;

export const ActionButtons = styled.div`
    display: flex;
    gap: 10px;
`;

export const ActionButton = styled.button`
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;

    &:hover {
        background-color: #45a049;
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;

export const Card = styled.div`
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const CardTitle = styled.h2`
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    color: #333;
`;

export const CardIcon = styled.span`
    margin-right: 10px;
    color: #4caf50;
`;

export const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

export const ListItem = styled.li`
    margin-bottom: 10px;
    font-size: 14px;
`;

export const NewsItem = styled(ListItem)`
    display: flex;
    align-items: center;
`;

export const NewsDate = styled.span`
    font-size: 12px;
    color: #666;
    margin-right: 10px;
`;

export const TeamStats = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
`;

export const StatItem = styled.div`
    text-align: center;
`;

export const StatValue = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #4caf50;
`;

export const StatLabel = styled.div`
    font-size: 12px;
    color: #666;
`;
