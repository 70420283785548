import React from 'react';

import { FaGamepad, FaChartLine, FaTrophy } from 'react-icons/fa';

import {
    StatGrid,
    StatCard,
    StatIcon,
    StatValue,
    StatLabel,
} from './profile-status.styles';

const ProfileStats = ({ stats }) => (
    <StatGrid>
        <StatCard>
            <StatIcon>
                <FaGamepad />
            </StatIcon>
            <StatValue>{stats.matches}</StatValue>
            <StatLabel>Matches</StatLabel>
        </StatCard>
        <StatCard>
            <StatIcon>
                <FaChartLine />
            </StatIcon>
            <StatValue>{stats.winRate}</StatValue>
            <StatLabel>Win Rate</StatLabel>
        </StatCard>
        <StatCard>
            <StatIcon>
                <FaTrophy />
            </StatIcon>
            <StatValue>{stats.rank}</StatValue>
            <StatLabel>Rank</StatLabel>
        </StatCard>
    </StatGrid>
);

export default ProfileStats;
