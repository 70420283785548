import {
    getAuth,
    setPersistence,
    browserLocalPersistence,
} from 'firebase/auth';

import app from './firebase.service';

// Initialize Firebase Authentication
const auth = getAuth(app);

// Ensure that the user's session persists across page reloads
setPersistence(auth, browserLocalPersistence);

export default auth;
