import React, { useState, useEffect } from 'react';

import {
    FaSearch,
    FaFilter,
    FaUser,
    FaUsers,
    FaTrophy,
    FaStar,
    FaGamepad,
    FaChartLine,
    FaShieldAlt,
    FaHandHoldingHeart,
    FaCrosshairs,
    FaMagic,
    FaFistRaised,
} from 'react-icons/fa';

import {
    ActionButton,
    Avatar,
    CardHeader,
    FilterActions,
    FilterButton,
    FilterGroup,
    FilterLabel,
    FilterModal,
    FilterSelect,
    IconButton,
    Name,
    Overlay,
    ResultCard,
    ResultsGrid,
    SearchContainer,
    SearchHeader,
    SearchInput,
    StatIcon,
    StatItem,
    StatList,
    Tab,
    TabContainer,
} from './search.styles';
import { ProfileHeroes, ProfilePositions } from '../../../components';
import { profileService } from '../../../services';

const SearchPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState('players');
    const [results, setResults] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        rank: '',
        position: '',
        winRate: '',
    });

    useEffect(() => {
        const init = async () => {
            const profile = await profileService.getProfilesWithNoTeam();
            const teams = [];

            setResults(activeTab === 'players' ? profile : teams);
        };

        init();
    }, [searchTerm, activeTab, filters]);

    const handleSearch = () => {
        // Implement search logic
    };

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const applyFilters = () => {
        setShowFilters(false);
        // Apply filters to search results
    };

    return (
        <SearchContainer>
            <SearchHeader>
                <SearchInput
                    type="text"
                    placeholder="Search players or teams..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <IconButton onClick={handleSearch}>
                    <FaSearch />
                </IconButton>
                <IconButton onClick={() => setShowFilters(true)}>
                    <FaFilter />
                </IconButton>
            </SearchHeader>

            <TabContainer>
                <Tab
                    $active={activeTab === 'players'}
                    onClick={() => setActiveTab('players')}
                >
                    <FaUser /> Players
                </Tab>
                <Tab
                    $active={activeTab === 'teams'}
                    onClick={() => setActiveTab('teams')}
                >
                    <FaUsers /> Teams
                </Tab>
            </TabContainer>

            <ResultsGrid>
                {results.map((result) => (
                    <ResultCard to={`/player/${result.uid}`} key={result.uid}>
                        <CardHeader>
                            <Avatar>
                                {activeTab === 'players' ? (
                                    <FaUser size={30} />
                                ) : (
                                    <FaUsers size={30} />
                                )}
                            </Avatar>
                            <Name>{result.username}</Name>
                        </CardHeader>
                        <StatList>
                            {activeTab === 'players' ? (
                                <>
                                    <StatItem>
                                        <StatIcon>
                                            <FaTrophy />
                                        </StatIcon>{' '}
                                        {result.stats.rank}
                                    </StatItem>
                                    <StatItem>
                                        <StatIcon>
                                            <FaChartLine />
                                        </StatIcon>{' '}
                                        Win Rate: {result.stats.winRate}
                                    </StatItem>
                                    <StatItem>
                                        <StatIcon>
                                            <FaGamepad />
                                        </StatIcon>{' '}
                                        Matches: {result.stats.matches}
                                    </StatItem>

                                    <StatItem>
                                        <ProfilePositions profile={result} />
                                    </StatItem>
                                    <StatItem>
                                        <ProfileHeroes profile={result} />
                                    </StatItem>
                                </>
                            ) : (
                                <>
                                    <StatItem>
                                        <StatIcon>
                                            <FaTrophy />
                                        </StatIcon>{' '}
                                        {result.rank}
                                    </StatItem>
                                    <StatItem>
                                        <StatIcon>
                                            <FaUsers />
                                        </StatIcon>{' '}
                                        Members: {result.members}
                                    </StatItem>
                                    <StatItem>
                                        <StatIcon>
                                            <FaStar />
                                        </StatIcon>{' '}
                                        W/L: {result.wins}/{result.losses}
                                    </StatItem>
                                </>
                            )}
                        </StatList>
                        <ActionButton>
                            {activeTab === 'players'
                                ? 'Invite to Team'
                                : 'Apply to Join'}
                        </ActionButton>
                    </ResultCard>
                ))}
            </ResultsGrid>

            {showFilters && (
                <>
                    <Overlay onClick={() => setShowFilters(false)} />
                    <FilterModal>
                        <FilterGroup>
                            <FilterLabel>Rank</FilterLabel>
                            <FilterSelect
                                name="rank"
                                value={filters.rank}
                                onChange={handleFilterChange}
                            >
                                <option value="">All Ranks</option>
                                <option value="Mythical Glory">
                                    Mythical Glory
                                </option>
                                <option value="Mythic">Mythic</option>
                                <option value="Legend">Legend</option>
                                <option value="Epic">Epic</option>
                            </FilterSelect>
                        </FilterGroup>
                        {activeTab === 'players' && (
                            <FilterGroup>
                                <FilterLabel>Position</FilterLabel>
                                <FilterSelect
                                    name="position"
                                    value={filters.position}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All Positions</option>
                                    <option value="Tank">Tank</option>
                                    <option value="Support">Support</option>
                                    <option value="Marksman">Marksman</option>
                                    <option value="Mage">Mage</option>
                                    <option value="Fighter">Fighter</option>
                                </FilterSelect>
                            </FilterGroup>
                        )}
                        <FilterGroup>
                            <FilterLabel>Win Rate</FilterLabel>
                            <FilterSelect
                                name="winRate"
                                value={filters.winRate}
                                onChange={handleFilterChange}
                            >
                                <option value="">All Win Rates</option>
                                <option value="60+">60%+</option>
                                <option value="50-60">50% - 60%</option>
                                <option value="40-50">40% - 50%</option>
                                <option value="0-40">Below 40%</option>
                            </FilterSelect>
                        </FilterGroup>
                        <FilterActions>
                            <FilterButton onClick={() => setShowFilters(false)}>
                                Cancel
                            </FilterButton>
                            <FilterButton onClick={applyFilters}>
                                Apply Filters
                            </FilterButton>
                        </FilterActions>
                    </FilterModal>
                </>
            )}
        </SearchContainer>
    );
};

export default SearchPage;
