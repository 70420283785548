import styled, { keyframes } from 'styled-components';

// Define the spinning animation
export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Container to center the spinner
export const LoaderContainer = styled.div`
    ${spin};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.background};
    z-index: 9999; // Ensures the loader is on top of other content
`;

// Spinner style
export const Spinner = styled.div`
    ${spin};
    border: 8px solid ${(props) => props.theme.border};
    border-radius: 50%;
    border-top: 8px solid ${(props) => props.theme.primary}; // Primary color for the top border
    width: 60px;
    height: 60px;
    animation: ${spin} 1s linear infinite;
`;
