// src/routes/index.js
import {
    RouterProvider as RouterProviderRRD,
    createBrowserRouter,
    Navigate,
} from 'react-router-dom';

import { appRoutes, authRoutes, landingRoutes } from '../routes';

import { Loading } from '../components';

const router = createBrowserRouter([
    landingRoutes,
    appRoutes,
    authRoutes,
    { path: '*', element: <Navigate to="/" replace /> },
]);

const RouterProvider = () => {
    return <RouterProviderRRD router={router} fallbackElement={<Loading />} />;
};

export default RouterProvider;
