import React from 'react';
import { FaUserCircle, FaEdit, FaSave } from 'react-icons/fa';

import {
    HeaderContainer,
    Avatar,
    AvatarImage,
    Name,
    GameID,
    EditButton,
    FileInput,
    InputField,
} from './profile-header.styles';

const ProfileHeader = ({
    profile,
    editedProfile = {},
    isEditing = false,
    handleChange,
    handleEditToggle,
    handleAvatarChange,
    handleSave,
    editable = false,
}) => {
    return (
        <HeaderContainer>
            <Avatar>
                {profile.avatarUrl || editedProfile.avatarUrl ? (
                    <AvatarImage
                        src={editedProfile.avatarUrl || profile.avatarUrl}
                        alt={profile.username}
                    />
                ) : (
                    <FaUserCircle size={100} color="#999" />
                )}
            </Avatar>
            {isEditing && (
                <FileInput
                    type="file"
                    accept="image/*"
                    onChange={handleAvatarChange}
                />
            )}
            <Name>{profile.username}</Name>
            {isEditing ? (
                <InputField
                    type="text"
                    name="gameId"
                    value={editedProfile.gameId}
                    onChange={handleChange}
                />
            ) : (
                <GameID>{profile.gameId}</GameID>
            )}

            {editable && (
                <EditButton onClick={isEditing ? handleSave : handleEditToggle}>
                    {isEditing ? (
                        <>
                            <FaSave style={{ marginRight: '5px' }} /> Save
                        </>
                    ) : (
                        <>
                            <FaEdit style={{ marginRight: '5px' }} /> Edit
                        </>
                    )}
                </EditButton>
            )}
        </HeaderContainer>
    );
};

export default ProfileHeader;
