import React from 'react';
import { Outlet } from 'react-router-dom';

import { Container, Header, Logo, NavLink, NavLinks } from './landing.styles';

const LandingLayout = () => {
    return (
        <Container>
            <Header>
                <Logo>LBML</Logo>
                <NavLinks>
                    <NavLink to="/sobre">Sobre</NavLink>
                    <NavLink to="/calendario">Calendário</NavLink>
                    <NavLink to="/login">Entrar</NavLink>
                </NavLinks>
            </Header>

            <Outlet />
        </Container>
    );
};

export default LandingLayout;
