import React, { useState, useEffect } from 'react';

import { FaTrophy } from 'react-icons/fa';

import {
    Bio,
    ProfileContainer,
    SectionTitle,
    TextAreaField,
} from './my-profile.styles';

import {
    Loading,
    ProfileHeader,
    ProfileHeroes,
    ProfileInfo,
    ProfilePositions,
    ProfileStats,
} from '../../../components/';

import { useAuth } from '../../../providers/auth.provider';

import { profileService } from '../../../services';

const MyProfilePage = () => {
    const [profile, setProfile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedProfile, setEditedProfile] = useState(null);

    const { state } = useAuth();
    const { user } = state;

    useEffect(() => {
        const fetchUserProfile = async () => {
            setProfile(user);
            setEditedProfile(user);
        };

        fetchUserProfile();
    }, [user]);

    const handleEditToggle = () => setIsEditing(!isEditing);

    const handleChange = (e) =>
        setEditedProfile({ ...editedProfile, [e.target.name]: e.target.value });

    const handleAvatarChange = (e) =>
        setEditedProfile({
            ...editedProfile,
            avatarUrl: URL.createObjectURL(e.target.files[0]),
        });

    const handleSave = () => {
        setProfile(editedProfile);
        setIsEditing(false);

        profileService.updateProfile(user.uid, editedProfile);
    };

    const handleAddPosition = (position) => {
        if (
            editedProfile.positions.length < 3 &&
            !editedProfile.positions.includes(position)
        ) {
            setEditedProfile({
                ...editedProfile,
                positions: [...editedProfile.positions, position],
            });
        }
    };

    const handleRemovePosition = (position) =>
        setEditedProfile({
            ...editedProfile,
            positions: editedProfile.positions.filter((p) => p !== position),
        });

    const handleAddHero = (hero) => {
        if (
            editedProfile.favoriteHeroes.length < 3 &&
            !editedProfile.favoriteHeroes.includes(hero)
        ) {
            setEditedProfile({
                ...editedProfile,
                favoriteHeroes: [...editedProfile.favoriteHeroes, hero],
            });
        }
    };

    const handleRemoveHero = (hero) =>
        setEditedProfile({
            ...editedProfile,
            favoriteHeroes: editedProfile.favoriteHeroes.filter(
                (h) => h !== hero,
            ),
        });

    if (!profile) return <Loading />;

    return (
        <ProfileContainer>
            <ProfileHeader
                profile={profile}
                editedProfile={editedProfile}
                isEditing={isEditing}
                handleChange={handleChange}
                handleEditToggle={handleEditToggle}
                handleAvatarChange={handleAvatarChange}
                handleSave={handleSave}
                editable={true}
            />

            <div>
                <ProfileStats stats={profile.stats} />

                <SectionTitle>Bio</SectionTitle>
                {isEditing ? (
                    <TextAreaField
                        name="bio"
                        value={editedProfile.bio}
                        onChange={handleChange}
                    />
                ) : (
                    <Bio>{profile.bio}</Bio>
                )}

                <SectionTitle>Player Info</SectionTitle>
                <ProfileInfo profile={profile} />

                <SectionTitle>Preferred Positions</SectionTitle>
                <ProfilePositions
                    profile={profile}
                    editedProfile={editedProfile}
                    isEditing={isEditing}
                    handleAddPosition={handleAddPosition}
                    handleRemovePosition={handleRemovePosition}
                />

                <SectionTitle>Favorite Heroes</SectionTitle>
                <ProfileHeroes
                    profile={profile}
                    editedProfile={editedProfile}
                    isEditing={isEditing}
                    handleAddHero={handleAddHero}
                    handleRemoveHero={handleRemoveHero}
                />

                <SectionTitle>Achievements</SectionTitle>
                <ul>
                    {profile.achievements.map((achievement, index) => (
                        <li key={index}>
                            <FaTrophy style={{ marginRight: '5px' }} />
                            {achievement}
                        </li>
                    ))}
                </ul>
            </div>
        </ProfileContainer>
    );
};

export default MyProfilePage;
