import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled.div`
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
    min-height: 100vh;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const Logo = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffd700;
`;

export const NavLinks = styled.nav`
    display: flex;
    gap: 1rem;
`;

export const NavLink = styled(Link)`
    color: #ffffff;
    text-decoration: none;
    &:hover {
        color: #ffd700;
    }
`;
