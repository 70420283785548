import styled from 'styled-components';

export const ProfileContainer = styled.div`
    padding: 10px;

    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

export const SectionTitle = styled.h2`
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 2px solid #4caf50;
    padding-bottom: 5px;
`;

export const Bio = styled.p`
    font-size: 14px;
    line-height: 1.5;
    color: white;
`;

export const TextAreaField = styled.textarea`
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    resize: vertical;
    min-height: 100px;
    color: black;
`;
