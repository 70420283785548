import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Avatar = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 15px;
`;

export const AvatarImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const Name = styled.h1`
    margin: 0;
    font-size: 24px;
    text-align: center;
`;

export const GameID = styled.h2`
    margin: 5px 0;
    font-size: 16px;
    color: #666;
    text-align: center;
`;

export const EditButton = styled.button`
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;

    &:hover {
        background-color: #45a049;
    }
`;

export const FileInput = styled.input.attrs({ type: 'file' })`
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
`;

export const InputField = styled.input`
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
    color: black;
`;
