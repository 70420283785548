// src/routes/appRoutes.js

import { AppLayout } from '../layouts';

import { AuthGuard } from '../guards/auth-guard';

import {
    DashboardPage,
    MyProfilePage,
    MyTeamPage,
    SearchPage,
    PlayerPage,
} from '../pages/app';

const appRoutes = {
    element: (
        <AuthGuard>
            <AppLayout />
        </AuthGuard>
    ),
    children: [
        {
            element: <DashboardPage />,
            path: '/home',
        },
        {
            element: <MyProfilePage />,
            path: '/my-profile',
        },
        {
            element: <MyTeamPage />,
            path: '/my-team',
        },
        {
            element: <SearchPage />,
            path: '/search',
        },
        {
            element: <PlayerPage />,
            path: '/player/:playerId',
        },
    ],
};

export default appRoutes;
