import React from 'react';

import {
    FaTimes,
    FaUserCircle,
    FaShieldAlt,
    FaHandHoldingHeart,
    FaCrosshairs,
    FaMagic,
    FaFistRaised,
} from 'react-icons/fa';

import {
    PositionList,
    PositionItem,
    SelectField,
} from './profile-positions.styles';

const positionIcons = {
    Tank: FaShieldAlt,
    Support: FaHandHoldingHeart,
    Marksman: FaCrosshairs,
    Mage: FaMagic,
    Fighter: FaFistRaised,
};

const ProfilePositions = ({
    profile,
    editedProfile = {},
    isEditing = false,
    handleAddPosition,
    handleRemovePosition,
}) => {
    const availablePositions = [
        'Tank',
        'Support',
        'Marksman',
        'Mage',
        'Fighter',
    ];

    return (
        <>
            {isEditing ? (
                <div>
                    <PositionList>
                        {editedProfile.positions.map((position, index) => {
                            const IconComponent =
                                positionIcons[position] || FaUserCircle;
                            return (
                                <PositionItem key={index}>
                                    <IconComponent
                                        style={{ marginRight: '5px' }}
                                    />
                                    {position}
                                    <FaTimes
                                        onClick={() =>
                                            handleRemovePosition(position)
                                        }
                                        style={{
                                            marginLeft: '10px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </PositionItem>
                            );
                        })}
                    </PositionList>
                    <SelectField
                        value=""
                        onChange={(e) => handleAddPosition(e.target.value)}
                    >
                        <option value="" disabled>
                            Add Position
                        </option>
                        {availablePositions
                            .filter(
                                (position) =>
                                    !editedProfile.positions.includes(position),
                            )
                            .map((position, index) => (
                                <option key={index} value={position}>
                                    {position}
                                </option>
                            ))}
                    </SelectField>
                </div>
            ) : (
                <PositionList>
                    {profile.positions.map((position, index) => {
                        const IconComponent =
                            positionIcons[position] || FaUserCircle;
                        return (
                            <PositionItem key={index}>
                                <IconComponent style={{ marginRight: '5px' }} />
                                {position}
                            </PositionItem>
                        );
                    })}
                </PositionList>
            )}
        </>
    );
};

export default ProfilePositions;
