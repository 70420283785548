import React from 'react';

import { FaTimes } from 'react-icons/fa';

import {
    PositionList,
    PositionItem,
    SelectField,
} from './profile-heroes.styles';

const ProfileHeroes = ({
    profile,
    editedProfile = {},
    isEditing = false,
    handleAddHero,
    handleRemoveHero,
}) => {
    const availableHeroes = [
        'Tigreal',
        'Estes',
        'Claude',
        'Lancelot',
        'Gusion',
    ];

    return (
        <>
            {isEditing ? (
                <div>
                    <PositionList>
                        {editedProfile.favoriteHeroes.map((hero, index) => (
                            <PositionItem key={index}>
                                {hero}
                                <FaTimes
                                    onClick={() => handleRemoveHero(hero)}
                                    style={{
                                        marginLeft: '10px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </PositionItem>
                        ))}
                    </PositionList>
                    <SelectField
                        value=""
                        onChange={(e) => handleAddHero(e.target.value)}
                    >
                        <option value="" disabled>
                            Add Hero
                        </option>
                        {availableHeroes
                            .filter(
                                (hero) =>
                                    !editedProfile.favoriteHeroes.includes(
                                        hero,
                                    ),
                            )
                            .map((hero, index) => (
                                <option key={index} value={hero}>
                                    {hero}
                                </option>
                            ))}
                    </SelectField>
                </div>
            ) : (
                <PositionList>
                    {profile.favoriteHeroes.map((hero, index) => (
                        <PositionItem key={index}>{hero}</PositionItem>
                    ))}
                </PositionList>
            )}
        </>
    );
};

export default ProfileHeroes;
