import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { AppHeader, AppFooter, AppSideMenu } from '../../components';

import { AppContainer, ContentWrapper, Main } from './app.styles';

const Layout = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSidebarMini, setIsSidebarMini] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const closeMenu = () => setIsMenuOpen(false);
    const toggleSidebarMini = () => setIsSidebarMini(!isSidebarMini);

    const location = useLocation();

    useEffect(() => {
        setIsMenuOpen(false);
    }, [location]);

    return (
        <AppContainer>
            <AppHeader toggleMenu={toggleMenu} />

            <ContentWrapper>
                <AppSideMenu
                    isOpen={isMenuOpen}
                    closeMenu={closeMenu}
                    isMini={isSidebarMini}
                    toggleSidebarMini={toggleSidebarMini}
                />
                <Main>
                    <Outlet />
                </Main>
            </ContentWrapper>

            <AppFooter />
        </AppContainer>
    );
};

export default Layout;
