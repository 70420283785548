import React from 'react';

import { FaUsers, FaCalendarAlt } from 'react-icons/fa';

import { InfoGrid, InfoItem, InfoIcon } from './profile-info.styles';

const ProfileInfo = ({ profile }) => (
    <InfoGrid>
        <InfoItem>
            <InfoIcon>
                <FaUsers />
            </InfoIcon>
            Team: {profile.team}
        </InfoItem>
        <InfoItem>
            <InfoIcon>
                <FaCalendarAlt />
            </InfoIcon>
            Joined: {profile.joinDate}
        </InfoItem>
    </InfoGrid>
);

export default ProfileInfo;
