import firestore from './firestore.service';

import {
    collection,
    doc,
    setDoc,
    getDoc,
    deleteDoc,
    query,
    where,
    getDocs,
} from 'firebase/firestore';

import { toast } from 'react-toastify';

const profilesCollection = collection(firestore, 'profiles');

const createProfile = async (uid, profileData) => {
    try {
        const date = new Date(); // Current date

        const formattedDate = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
        });

        const profileDocRef = doc(profilesCollection, uid);
        await setDoc(profileDocRef, {
            uid,
            email: profileData.email,
            emailVerified: profileData.emailVerified,
            username: profileData.profilename,
            gameId: '',
            avatarUrl: '',
            stats: {
                matches: 0,
                winRate: '0%',
                rank: 'Unkown',
            },
            positions: [],
            bio: '',
            team: '',
            joinDate: formattedDate,
            favoriteHeroes: [],
            achievements: [],
        });
        console.log('Profile profile created successfully');
    } catch (error) {
        console.error('Error creating profile profile:', error);
        throw error; // rethrow the error to handle it in the calling function
    }
};

const getProfile = async (uid) => {
    try {
        const profileDocRef = doc(profilesCollection, uid);
        const profileDoc = await getDoc(profileDocRef);
        if (profileDoc.exists()) {
            return profileDoc.data();
        } else {
            console.log('No such profile found');
            return null;
        }
    } catch (error) {
        console.error('Error fetching profile profile:', error);
        throw error;
    }
};

const getProfilesWithNoTeam = async () => {
    try {
        const q = query(profilesCollection, where('team', '==', ''));

        // Execute the query
        const querySnapshot = await getDocs(q);

        const profiles = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        return profiles;
    } catch (error) {
        console.error('Error fetching profiles:', error);
        throw error;
    }
};

const updateProfile = async (uid, updatedData) => {
    try {
        const profileDocRef = doc(profilesCollection, uid);
        await setDoc(profileDocRef, updatedData, { merge: true });

        toast.success('Profile profile updated successfully');
    } catch (error) {
        toast.error('Error updating profile profile');
        throw error;
    }
};

const deleteProfile = async (uid) => {
    try {
        const profileDocRef = doc(profilesCollection, uid);
        await deleteDoc(profileDocRef);
        console.log('Profile profile deleted successfully');
    } catch (error) {
        console.error('Error deleting profile profile:', error);
        throw error;
    }
};

const profileService = {
    createProfile,
    getProfile,
    getProfilesWithNoTeam,
    updateProfile,
    deleteProfile,
};

export default profileService;
